import React, { useEffect, useState } from "react";
import Menu from "../../components/Menu/Menu";
import Pagination from "../../components/Pagination/Pagination";
import Footer from "../../components/Footer/Footer";
import { useHistory, useLocation } from "react-router-dom";
import { getMonitors, deleteMonitor, startMonitor, stopMonitor } from "../../services/MonitorsService";
import MonitorRow from "./MonitorRow";
import MonitorModal from "./MonitorModal/MonitorModal";
import Toast from '../../components/Toast/Toast';
import LogModal from "../../components/Logs/LogModal";
import NewMonitorButton from "./NewMonitorButton";
import BeholderButton from "./Beholder/BeholderButton";
import BeholderModal from "./Beholder/BeholderModal";



function Monitors() {

    const history = useHistory();

    const DEFAULT_MONITOR = {
        id: 0,
        symbol: '',
        type: 'CANDLES',
        broadcastLabel: '',
        interval: '1m',
        indexes: '',
        isActive: false,
        isSystemMon: false,
        logs: false
    }


    const defaultLocation = useLocation();
    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }


    const [count, setCount] = useState(0);
    const [monitors, setMonitors] = useState([]);
    const [page, setPage] = useState(getPage());
    const [editMonitor, setEditMonitor] = useState(DEFAULT_MONITOR);
    const [notification, setNotification] = useState({ type: '', text: '' });

    useEffect(() => {
        return history.listen(location => {
            setPage(getPage(location));
        })
    }, [history])

    useEffect(() => {
        const token = localStorage.getItem('token');
        getMonitors(page || 1, token)
            .then(result => {
                setMonitors(result.rows);
                setCount(result.count);
            }).catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });
    }, [page])

    function onEditClick(event) {
        const id = event.target.id.replace('edit', '');
        const monitor = monitors.find(m => m.id == id);
        setEditMonitor({ ...monitor });
    }

    function onLogsClick(event) {
        const id = event.target.id.replace('logs', '');
        setEditMonitor(monitors.find(m => m.id == id));
    }

    function onNewMonitorClick(event) {
        setEditMonitor(DEFAULT_MONITOR);
    }

    function onStopClick(event) {
        const id = event.target.id.replace('stop', '');
        const token = localStorage.getItem('token');
        stopMonitor(id, token)
            .then(monitor => { history.go(0) })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });
    }

    function onStartClick(event) {
        const id = event.target.id.replace('start', '');
        const token = localStorage.getItem('token');
        startMonitor(id, token)
            .then(monitor => { history.go(0) })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message)
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });
    }

    function onDeleteClick(event) {
        const id = event.target.id.replace('delete', '');
        const token = localStorage.getItem('token');
        deleteMonitor(id, token)
            .then(monitor => { history.go(0) })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });
    }

    function onModalSubmit(event) {
        history.go(0);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4">Monitors</h2>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <BeholderButton />
                            <NewMonitorButton onClick={onNewMonitorClick} />
                        </div>
                    </div>
                </div>
                <div className="card card-body border-0 shadow table-wrapper table-table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200">Type</th>
                                <th className="border-gray-200">Symbol</th>
                                <th className="border-gray-200">Active</th>
                                <th className="border-gray-200">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                monitors && monitors.length
                                    ? monitors.map(monitor => (<MonitorRow key={monitor.id} data={monitor} onEditClick={onEditClick} onStartClick={onStartClick} onStopClick={onStopClick} onDeleteClick={onDeleteClick} onLogsClick={onLogsClick} />))
                                    : <React.Fragment></React.Fragment>
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>
                <Footer />
            </main>
            <MonitorModal data={editMonitor} onSubmit={onModalSubmit} />
            <LogModal file={editMonitor.id > 0 ? "M -" + editMonitor.id : ""} />
            <BeholderModal />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    )

}

export default Monitors;