import React, { useRef, useState, useEffect } from 'react';
import SelectSymbol from '../../../components/SelectSymbol/SelectSymbol';
import SwitchInput from '../../../components/SwitchInput/SwitchInput';
import { saveAutomation, saveGrid } from '../../../services/AutomationsService';
import SymbolPrice from '../../../components/SymbolPrice/SymbolPrice';
import WalletSummary from '../../../components/WalletSummary/WalletSummary';
import { getIndexes, getMemoryIndex } from '../../../services/BeholderService';
import { getSymbol } from '../../../services/SymbolsService';
import '../Automations.css';
import GridTable from './GridTable';
import GridButton from './GridButton';
import LogButton from '../../../components/Logs/LogButton';
import LogView from '../../../components/Logs/LogView';



/**
 * props:
 * - data
 * - onSubmit
 */
function GridModal(props) {


    const btnClose = useRef('');
    const btnSave = useRef('');
    const referencePrice = useRef('');

    const [gridView, setGridView] = useState(false)
    const [symbol, setSymbol] = useState({});
    const [error, setError] = useState('');
    const [wallet, setWallet] = useState({ base: { symbol: '', qty: 0 }, quote: { symbol: '', qty: 0 } });
    const [isVisible, setIsVisible] = useState(false);


    const DEFAULT_AUTOMATION = {
        name: '',
        indexes: '',
        conditions: '',
        actions: []
    }

    const [automation, setAutomation] = useState(DEFAULT_AUTOMATION);

    const DEFAULT_GRID = {
        lowerLimit: '',
        upperLimit: '',
        levels: '',
        quantity: ''
    }

    const [grid, setGrid] = useState(DEFAULT_GRID);


    useEffect(() => {
        const modal = document.getElementById('modalGrid');
        modal.addEventListener('hidden.bs.modal', (event) => {
            setIsVisible(false);
            setGridView(false);
            setAutomation(DEFAULT_AUTOMATION);
            setShowLogs(false);
        })
        modal.addEventListener('shown.bs.modal', (event) => {
            setIsVisible(true);
            setGridView(false);
        })

    }, [])

    useEffect(() => {
        if (!automation.symbol) return;

        setError('');
        const token = localStorage.getItem('token');
        getSymbol(automation.symbol, token)
            .then(result => {
                setSymbol(result);
                if (grid.quantity === 'Min. Notional')
                    referencePrice.current.value = `${symbol.minNotional}`;
                else
                    referencePrice.current.value = `${grid.quantity * grid.lowerLimit}`.substring(0, 10);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data : err.message);
            })
    }, [automation.symbol])

    useEffect(() => {
        if (!symbol || !symbol.base) return;
        loadWallet(symbol);
    }, [symbol])

    async function onSubmit(event) {
        setError('');
        const token = localStorage.getItem('token');

        automation.name = `GRID ${automation.symbol} #${grid.levels}`;
        automation.actions = [{ type: 'GRID' }];
        automation.indexes = `${automation.symbol}:BOOK`;
        automation.conditions = `MEMORY['${automation.symbol}:BOOK'].current.bestAsk>${grid.lowerLimit} && MEMORY['${automation.symbol}:BOOK'].current.bestBid<${grid.upperLimit}`;

        const quantity = grid.quantity === 'Min. Notional' ? 'MIN_NOTIONAL' : grid.quantity;

        saveGrid(automation.id, automation, grid.levels, quantity, token)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data : err.message);
            })
    }

    async function loadWallet(symbol) {
        const token = localStorage.getItem('token');

        try {
            const baseQty = await getMemoryIndex(symbol.base, 'WALLET', '', token);
            const quoteQty = await getMemoryIndex(symbol.quote, 'WALLET', '', token);
            setWallet({ base: { symbol: symbol.base, qty: baseQty }, quote: { symbol: symbol.quote, qty: quoteQty } });
        } catch (err) {
            console.log(err => err.response ? err.response.data : err.message);
            setError(err.message);
        }
    }

    function onGridChange(event) {
        const value = event.target.value === 'Min. Notional' ? 'MIN_NOTIONAL' : parseFloat(event.target.value.replace(',', '.'));
        grid[event.target.id] = value;

        setGrid(prevState => ({ ...prevState, [event.target.id]: value }));

        if (event.target.id === 'quantity' && value < parseFloat(symbol.minLotSize)) {
            setError('Min. Lot Size: ' + symbol.minLotSize);
            btnSave.current.disabled = true;
            return;
        }
        else if (event.target.id === 'quantity' || event.target.id === 'lowerLimit') {
            const notional = grid.lowerLimit * grid.quantity;
            referencePrice.current.value = `${notional}`.substring(0, 10);

            if (notional < parseFloat(symbol.minNotional)) {
                setError('Min. Notional: ' + symbol.minNotional);
                btnSave.current.disabled = true;
                return;
            }
        }

        btnSave.current.disabled = false;
        setError('');
    }

    function onInputChange(event) {
        setAutomation(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    useEffect(() => {
        if (!props.data) return;
        setAutomation(props.data);

        if (!props.data.id || !props.data.grids || !props.data.grids.length) return setGrid(DEFAULT_GRID);

        const conditionSplit = props.data.conditions.split(' && ')
        if (!conditionSplit || conditionSplit.length < 2) return;

        const quantity = props.data.grids[0].orderTemplate.quantity;
        setGrid({
            lowerLimit: parseFloat(conditionSplit[0].split('>')[1]),
            upperLimit: parseFloat(conditionSplit[1].split('<')[1]),
            levels: props.data.grids.length + 1,
            quantity: quantity === 'MIN_NOTIONAL' ? 'Min. Notional' : quantity
        });

    }, [props.data.id])


    function onViewGridsClick(event) {
        if (!gridView) setShowLogs(false);
        setGridView(!gridView);
    }

    const [showLogs, setShowLogs] = useState(false);
    function onLogClick(event) {
        if(!showLogs) setGridView(false);
        setShowLogs(!showLogs);
    }



    return (
        <div className="modal fade" id="modalGrid" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.id ? 'Edit ' : 'New '}Grid</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="symbol">Symbol:</label>
                                    <SelectSymbol onChange={onInputChange} symbol={automation.symbol} onlyFavorites={false} disabled={automation.id > 0} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    {
                                        isVisible
                                            ? <SymbolPrice symbol={automation.symbol} />
                                            : <React.Fragment></React.Fragment>
                                    }

                                </div>
                            </div>
                        </div>
                        {
                            !gridView && !showLogs
                                ? (
                                    <React.Fragment>
                                        <div className="form-group">
                                            <WalletSummary wallet={wallet} symbol={symbol} />
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor='lowerLimit'>Lower Limit:</label>
                                                    <input className="form-control" id="lowerLimit" type="number" placeholder="0" value={grid.lowerLimit || ''} onChange={onGridChange} />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor='upperLimit'>Upper Limit:</label>
                                                    <input className="form-control" id="upperLimit" type="number" placeholder="0" value={grid.upperLimit || ''} onChange={onGridChange} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor='levels'>Levels:</label>
                                                    <input className="form-control" id="levels" type="number" placeholder="3" value={grid.levels || ''} onChange={onGridChange} />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor='quantity'>Quantity:</label>
                                                    <input className="form-control" list="gridQtyList" id="quantity" type="text" placeholder={symbol.minLotSize} value={grid.quantity} onChange={onGridChange} />
                                                    <datalist id="gridQtyList">
                                                        <option>Min. Notional</option>
                                                    </datalist>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="referencePrice">Notional Price:</label>
                                                        <input ref={referencePrice} className="form-control" id="referencePrice" type="number" placeholder="0" disabled />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <SwitchInput id="isActive" text="Is Active?" onChange={onInputChange} isChecked={automation.isActive} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <div className="form-group">
                                                        <SwitchInput id="logs" text="Enable Logs?" onChange={onInputChange} isChecked={automation.logs} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                                : <React.Fragment></React.Fragment>
                        }
                        {
                            gridView && !showLogs
                                ? (
                                    <div className={"form-group"}>
                                        <GridTable data={automation.grids} />
                                    </div>
                                )
                                : <React.Fragment></React.Fragment>
                        }
                        {
                            showLogs
                                ? <LogView file={"A -" + automation.id} />
                                : <React.Fragment></React.Fragment>
                        }
                    </div>
                    <div className="modal-footer">
                        {
                            error
                                ? <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>
                                : <React.Fragment></React.Fragment>
                        }
                        <GridButton id={automation.id} onClick={onViewGridsClick} />
                        <LogButton id={automation.id} onClick={onLogClick} />
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default GridModal;

